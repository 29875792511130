/* ApplicantCompanies.css */

.table-container {
  display: flex;
  justify-content: center;
  width: 100%;
  /* Add these lines */
  /* background-image: url('https://source.unsplash.com/random/1600x900?applicant'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
}

.table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  position: relative;
  /* Add these lines */
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table-content-wrapper {
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 640px) {
  .table-content-wrapper {
    padding: 0 2rem;
  }
}

@media (min-width: 1024px) {
  .table-content-wrapper {
    padding: 0 4rem;
  }
}

/* New class to center the table */
.table-wrapper {
  margin: 0 auto;
  width: 100%;
}
