/* FilterInputs.css */

.input-container {
    position: relative;
    display: inline-block;
  }
  
  .input-label {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 5px;
    padding: 2px;
    background-color: #ffffff;
    color: #888;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 0.8rem;
    transition: 0.2s;
    pointer-events: none;
    opacity: 0;
    white-space: nowrap;
    z-index: 10;
  }
  
  .input-container:hover .input-label {
    opacity: 1;
  }
  