/* TableHints.css */

.screen-size-limit {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 1300px) {
  .screen-size-limit {
    width: 1300px;
  }
}
