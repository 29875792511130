/* CustomButton.css */

.custom-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
    border: none;
  }
  
  .custom-button.disabled {
    cursor: not-allowed;
    background-color: #a5a5a5;
  }
  
  .custom-button:hover:not(.disabled) {
    filter: brightness(120%);
  }