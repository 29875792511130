/* Container for the search bar */
.searchbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

/* Form containing the search input and buttons */
.searchbar-form {
  display: flex;
  align-items: center;
  position: relative;
}

/* Style for the search input field */
.searchbar-input {
  width: 300px;
  padding: 0.5rem;
  padding-left: 40px;
  padding-right: 60px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

/* Style for the search icon */
.search-icon {
  position: absolute;
  left: 0.75rem;
  font-size: 1rem;
  color: #aaa;
  transition: all 0.2s ease;
}

/* Style for the reset button container */
.searchbar-reset {
  position: absolute;
  right: 23%;
  top: 50%;
  transform: translateY(-50%);
}
