/* TableCompanies.css */


.table-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.table-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  position: relative;

}

.table-content-wrapper {
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 640px) {
  .table-content-wrapper {
    padding: 0 2rem;
  }
}

@media (min-width: 1024px) {
  .table-content-wrapper {
    padding: 0 4rem;
  }
}

/* New class to center the table */
.table-wrapper {
  margin: 0 auto;
  width: 100%;
}