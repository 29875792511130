/* index.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.table-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.Modal--isOpen {
  opacity: 1;
}

/* Modal animations */
.modal-transition-enter {
  opacity: 0;
  transform: translateY(-20px);
}
.modal-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.modal-transition-exit {
  opacity: 1;
  transform: translateY(0);
}
.modal-transition-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

/* InputField styles */
.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-field label {
  margin-bottom: 0.5rem;
}

/*  */
.modal-content {
  width: 50%;
  max-width: 100%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

@tailwind base;
@tailwind components;
@tailwind utilities;