/* SendNotificationForm.css */

.transition-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  
  .transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  
  .transition-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .transition-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }